import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import ButtonOutlined from "../components/ButtonOutlined";
import SideBar from "../components/SideBar";

const PoliticaPrivacidade = (props) => {
  return (
    <Layout>
      <SEO
        type="website"
        title="Construir Sozinho - Política de Privacidade"
        description="Política de privacidade de Construir Sozinho"
      />
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-8/12">
          <div className="bg-gray-700 rounded-b-md mt-2 pb-4 text-justify">
            <h1 className="text-center text-white text-4xl font-bold ">
              Política de Privacidade
            </h1>
            <div className="text-white px-8">
              <h2 className="text-white text-3xl font-semibold">
                Política de privacidade para{" "}
                <Link
                  className="text-yellow-400 hover:underline cursor-pointer"
                  activeClassName="text-yellow-400 hover:underline cursor-pointer"
                  to="/"
                >
                  Construir Sozinho
                </Link>
              </h2>
              <p className="text-white text-base pb-2">
                Todas as suas informações pessoais recolhidas, serão usadas para
                o ajudar a tornar a sua visita no nosso site o mais produtiva e
                agradável possível.
              </p>
              <p className="text-white text-base pb-2">
                A garantia da confidencialidade dos dados pessoais dos
                utilizadores do nosso site é importante para o Engenheira do
                Excel.
              </p>
              <p className="text-white text-base pb-2">
                Todas as informações pessoais relativas a membros, assinantes,
                clientes ou visitantes que usem o Construir Sozinho serão
                tratadas em concordância com a Lei da Proteção de Dados Pessoais
                de 26 de outubro de 1998 (Lei n.º 67/98).
              </p>
              <p className="text-white text-base pb-2">
                A informação pessoal recolhida pode incluir o seu nome, e-mail,
                número de telefone e/ou telemóvel, morada, data de nascimento
                e/ou outros.
              </p>
              <p className="text-white text-base pb-2">
                O uso do Construir Sozinho pressupõe a aceitação deste Acordo de
                privacidade. A equipa do Construir Sozinho reserva-se ao direito
                de alterar este acordo sem aviso prévio. Deste modo,
                recomendamos que consulte a nossa política de privacidade com
                regularidade de forma a estar sempre atualizado.
              </p>
              <h2 className="text-white text-3xl font-semibold">Os anúncios</h2>
              <p className="text-white text-base pb-2">
                Tal como outros websites, coletamos e utilizamos informação
                contida nos anúncios. A informação contida nos anúncios, inclui
                o seu endereço IP (Internet Protocol), o seu ISP (Internet
                Service Provider, como o Sapo, Clix, ou outro), o browser que
                utilizou ao visitar o nosso website (como o Internet Explorer ou
                o Firefox), o tempo da sua visita e que páginas visitou dentro
                do nosso website.
              </p>
              <p className="text-white text-base pb-2">
                Terceiros, incluindo o Google, usam cookies para veicular
                anúncios com base em visitas anteriores do usuário ao seu
                website ou a outros websites.
              </p>
              <p className="text-white text-base pb-2">
                Com o uso de cookies de publicidade, o Google e os parceiros
                dele podem veicular anúncios para os usuários com base nas
                visitas feitas aos seus sites e/ou a outros sites na Internet.
              </p>
              <p className="text-white text-base pb-2">
                Os usuários podem desativar a publicidade personalizada
                acessando as&nbsp;
                <a
                  className="text-yellow-400 hover:underline cursor-pointer"
                  href="https://www.google.com/settings/ads"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Configurações de anúncios
                </a>
                . Como alternativa, você pode orientar os usuários a acessar o
                site&nbsp;
                <a
                  className="text-yellow-400 hover:underline cursor-pointer"
                  href="http://www.aboutads.info/choices/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.aboutads.info
                </a>
                &nbsp;para desativar o uso de cookies de publicidade
                personalizada de terceiros.
              </p>
              <h2 className="text-white text-3xl font-semibold">
                Os Cookies e Web Beacons
              </h2>
              <p className="text-white text-base pb-2">
                Utilizamos cookies para armazenar informação, tais como as suas
                preferências pessoas quando visita o nosso website.
              </p>
              <p className="text-white text-base pb-2">
                Em adição também utilizamos publicidade de terceiros no nosso
                website para suportar os custos de manutenção. Alguns destes
                publicitários, poderão utilizar tecnologias como os cookies e/ou
                web beacons quando publicitam no nosso website, o que fará com
                que esses publicitários (como o Google através do Google
                AdSense) também recebam a sua informação pessoal, como o
                endereço IP, o seu ISP, o seu browser, etc. Esta função é
                geralmente utilizada para geotargeting (mostrar publicidade de
                Lisboa apenas aos leitores oriundos de Lisboa por ex.) ou
                apresentar publicidade direcionada a um tipo de utilizador (como
                mostrar publicidade de restaurante a um utilizador que visita
                sites de culinária regularmente, por ex.).
              </p>
              <h2 className="text-white text-3xl font-semibold">
                Ligações a Sites de terceiros
              </h2>
              <p className="text-white text-base pb-2">
                O Construir Sozinho possui ligações para outros sites, os quais,
                a nosso ver, podem conter informações / ferramentas úteis para
                os nossos visitantes. A nossa política de privacidade não é
                aplicada a sites de terceiros, pelo que, caso visite outro site
                a partir do nosso deverá ler a politica de privacidade do mesmo.
              </p>
              <p className="text-white text-base pb-2">
                Não nos responsabilizamos pela política de privacidade ou
                conteúdo presente nesses mesmos sites.
              </p>
            </div>
          </div>
          <div className="mt-2 flex justify-between">
            <ButtonOutlined text="VOLTAR" to="/" />
          </div>
        </div>
        <div className="w-full lg:w-4/12 mt-2 lg:pl-2 text-white">
          <SideBar />
        </div>
      </div>
    </Layout>
  );
};
export default PoliticaPrivacidade;
